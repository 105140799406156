import React from 'react'

import {
  Button,
  Buttons,
  Columns,
  Heading,
  List,
  ListItem,
  Main,
  ModalBody,
  ModalContent,
  Paragraph,
  Strong
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Content } from '../../components/Content'
import { NoteItem } from '../../components/NoteItem'
import { Section } from '../../components/Section'
import { ZoomedExample } from '../../components/ZoomedExample'
import { SectionHeading } from '../../components/SectionHeading'
import { Link } from '../../components/Link'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content heading="Navigointi pois lomakkeelta">
    <Section title="Periaatteet">
      <List variant="unordered">
        <ListItem>
          Lomakkeelta on tarkoitus poistua käyttäen lomakkeen omia painikkeita.
          Jos käyttäjä yrittää navigoida esimerkiksi selaimen
          takaisin-painikkeella, häntä varoitetaan, että hän on poistumassa
          lomakkeelta vaikka se on vielä kesken.
        </ListItem>
        <ListItem>
          Kun käyttäjä yrittää navigoida pois, esitetään varmistusdialogi (
          <Code>
            <Link page="Modal" />
          </Code>
          ), jolla hyväksytään poistuminen tai palataan takaisin lomakkeelle.
          Jos käyttäjä poistuu lomakkeelta, lomakkeen tiedot pysyvät
          tallennettuina ja niihin voi palata tapahtumavirran tapahtumakortin
          kautta. Lomake ja tapahtumakortti ovat samassa tilassa kuin{' '}
          <Strong>Jatka myöhemmin</Strong> -toiminnon jälkeen.
        </ListItem>
      </List>
    </Section>
    <Section title="Rakenne ja toiminnallisuus">
      <Paragraph>
        <Strong>Haluatko varmasti poistua?</Strong> -dialogi on geneerinen ja
        esitetään kaikkialla samanlaisena.
      </Paragraph>
      <NoteItem
        bullet="1"
        title="Varmistusdialogin otsikko"
      />
      <NoteItem
        bullet="2"
        title="Varmistusdialogin sisältö"
      />
      <NoteItem
        bullet="3"
        title="Varmistusdialogin painikkeet"
      >
        <List
          noMargin
          variant="unordered"
        >
          <ListItem>
            <Strong>Peruuta</Strong>-toiminto palaa takaisin lomakkeelle.{' '}
            <Strong>Poistu</Strong>-toiminto jättää lomakkeen keskeneräiseksi ja
            sulkee lomakkeen.
          </ListItem>
        </List>
      </NoteItem>
      <ZoomedExample notes={[{ id: '1' }, { id: '2' }, { id: '3' }]}>
        <Columns layout="single">
          <Main>
            <ModalContent size="lg">
              <ModalBody>
                <div data-note="1">
                  <Heading
                    id="modal-heading"
                    size={4}
                  >
                    Haluatko varmasti poistua?
                  </Heading>
                </div>
                <div data-note="2">
                  <Paragraph>
                    Tietosi on tallennettu, mutta et ole vielä lähettänyt
                    tietojasi.
                  </Paragraph>
                  <Paragraph>
                    Jos poistut, voit palata takaisin Oma työnhaku -sivun
                    tapahtumista.
                  </Paragraph>
                </div>
                <div data-note="3">
                  <Buttons
                    align="right"
                    noMargin
                  >
                    <Button variant="outline">Peruuta</Button>
                    <Button>Poistu</Button>
                  </Buttons>
                </div>
              </ModalBody>
            </ModalContent>
          </Main>
        </Columns>
      </ZoomedExample>
    </Section>
    <Section title="Esimerkkejä käyttötapauksista">
      <SectionHeading>
        Navigointi pois kesken Työnhaun aloitus -lomakkeen täyttämistä
      </SectionHeading>
      <List variant="unordered">
        <ListItem>
          Käyttäjä on täyttämässä <Strong>Työnhaun aloitus</Strong> -lomaketta,
          mutta päättääkin kesken lomakkeen täyttämisen siirtyä ylänavigaatiosta
          Omille sivuille. Varmistusdialogi esitetään ennen siirtymistä Omille
          sivuille.
        </ListItem>
      </List>
    </Section>
  </Content>
)

export default Page
